<template>
  <div>
    <b-card no-body header-tag="header" :class="[d_fullScreenGeneral ?'full-screen-mode' :'normal-mode']">
      <b-card-header class="p-1">
        <b-row>
          <!--           <b-col cols="1" style="text-align: center;">
            <template v-if="d_timerControlUpdate.status === 'active'">
              <b-button class="pull-right" size="md" variant="white" style="margin-right: 5px; padding: 0px;" @click="d_activityData.show = true;">
                <img src="@/icon/sandwatch.gif" style="width: 2em;" />
              </b-button>
            </template>
          </b-col>
 -->
          <b-col :cols="p_reportList.index === '' ? 2 : 4">
            <b-button v-if="p_reportData.index !== ''" variant="white" size="sm" @click="p_reportList.index = ''; p_reportData.index = ''; p_reportData.list = [];" title="Rapor Modülü Anasyfa">
              <img src="@/icon/710231.png" style="width: 2em;" />
            </b-button>
            <b-button v-if="p_reportList.list.length > 0" variant="white" size="sm" @click="p_reportList.show = true" title="Rapor Listesi">
              <img src="@/icon/2504347.png" style="width: 2em;" />
            </b-button>
            <strong v-if="p_reportList.index !== ''" :title="p_reportList.list[p_reportList.index].name">
              {{ p_reportList.list[p_reportList.index].name.length > 30 ? p_reportList.list[p_reportList.index].name.substring(0,30) + '...' : p_reportList.list[p_reportList.index].name }}
            </strong>
            <template v-if="p_reportData.status === 0 && p_reportList.index !== ''">
              <img src="@/icon/sandwatch.gif" style="width: 1.5em;" /> <small style="color: red;">veri hazırlanıyor</small>
            </template>
          </b-col>
          <b-col cols="2" v-if="p_reportList.index === ''">
            <b-form-input v-model="d_searchDashboardText" placeholder="filtrelemek için yazınız."></b-form-input>
          </b-col>
          <b-col cols="2" v-if="p_reportData.list.length > 1">
            <b-form-select v-model="p_reportData.index">
              <option v-for="(rep_data, rep_data_index) in p_reportData.list" :value="rep_data_index">
                {{ rep_data.name }}
              </option>
            </b-form-select>
          </b-col>
          <b-col :cols="p_reportData.list.length > 1 ? 2 : 4">
            <template v-if="p_reportData.index !== ''">
              <b-form-select v-model="p_reportData.table_index">
                <option v-for="(table_data, table_data_index) in p_reportData.list[p_reportData.index].table_list" :value="table_data_index">
                  {{ table_data_index + 1 }} - {{ table_data.name }}
                </option>
              </b-form-select>
            </template>
          </b-col>
          <b-col cols="3">
            <b-button v-if="p_reportList.index !== '' && p_reportData.table_index !== ''" size="sm" variant="white" @click="f_createNewChart()" title="Grafik Oluştur">
              <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Grafik Ekle
            </b-button>
          </b-col>
          <b-col cols="1">
            <b-button class="pull-right" size="md" :variant="d_fullScreenGeneral ? 'warning' : 'white'" :style="d_fullScreenGeneral ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenGeneral ? d_fullScreenGeneral = false : d_fullScreenGeneral = true">
              <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <template v-if="p_reportData.index === ''">
        <b-tabs v-if="!d_searchDashboardText">
          <template v-if="d_dashboardRows && d_dashboardRows.dashboard_group.length > 0 && d_dashboardRows.dashboard_group.length > 0">
            <template v-for="(dashboard_caption, dashboard_caption_index) in p_reportDashboard.dashboard_group.order">
              <b-tab :style="f_calculateStyleDashboardTab()">
                <template slot="title">
                  <img src="@/icon/665778.png" style="width: 1.5em;">
                  <span>{{ dashboard_caption }}</span>
                </template>
                <template v-for="(row, row_index) in d_dashboardRows.dashboard_group">
                  <template v-for="(row_item, row_item_index) in row.list">
                    <template v-if="row_item.caption === dashboard_caption">
                      <template v-if="p_reportDashboard.dashboard_group.list[dashboard_caption].list.length > 0">
                        <template v-if="p_reportDashboard.dashboard_group.list[dashboard_caption].list[row_item.view_index].data && p_reportDashboard.dashboard_group.list[dashboard_caption].list[row_item.view_index].data.type === 'chart' && p_reportDashboard.dashboard_group.list[dashboard_caption].list[row_item.view_index].data.charts.length > 0">
                          <div :style="f_calculateCols(row_item)">
                            <analyze-report-view :key="'dashboard_' + dashboard_caption + '_' + row_item.view_index + '_' + row_item.chart_index" :p_analyzeReports="[p_reportDashboard.dashboard_group.list[dashboard_caption].list[row_item.view_index].data.charts[row_item.chart_index]]"></analyze-report-view>
                          </div>
                        </template>
                      </template>
                    </template>
                  </template>
                </template>
              </b-tab>
            </template>
          </template>
          <template v-if="p_reportDashboard && p_reportDashboard.other.order.length > 0">
            <b-tab>
              <template slot="title">
                <img src="@/icon/665778.png" style="width: 1.5em;">
                <span>Diğer</span>
              </template>
              <template v-for="(other_id, other_id_index) in p_reportDashboard.other.order">
                <template v-if="p_reportDashboard.other.list[other_id].list.length > 0">
                  <template v-for="(v_item, v_item_index) in p_reportDashboard.other.list[other_id].list">
                    <template v-if="v_item.data.type === 'chart' && v_item.data.charts.length > 0">
                      <b-row style="margin: 3px; overflow-x: auto; overflow-y: auto; height: 400px; width: 1000px;">
                        <b-col cols="10">
                          <analyze-report-view :key="'other_' + other_id_index + '_' + v_item_index" :p_analyzeReports="v_item.data.charts"></analyze-report-view>
                        </b-col>
                        <b-col cols="2"></b-col>
                      </b-row>
                    </template>
                  </template>
                </template>
              </template>
            </b-tab>
          </template>
        </b-tabs>
        <b-tabs v-if="d_searchDashboardText">
          <b-tab :style="f_calculateStyleDashboardTab()">
            <template slot="title">
              <img src="@/icon/665778.png" style="width: 1.5em;">
              <span>Arama Listesi</span>
            </template>
            <template v-for="(row_item, row_item_index) in d_searchViewList">
              <template v-if="p_reportDashboard.dashboard_group.list[row_item.caption].list[row_item.view_index].data && p_reportDashboard.dashboard_group.list[row_item.caption].list[row_item.view_index].data.type === 'chart' && p_reportDashboard.dashboard_group.list[row_item.caption].list[row_item.view_index].data.charts.length > 0">
                <div :style="f_calculateCols(row_item)">
                  <analyze-report-view :key="'dashboard_' + row_item.caption + '_' + row_item.view_index + '_' + row_item.chart_index" :p_analyzeReports="[p_reportDashboard.dashboard_group.list[row_item.caption].list[row_item.view_index].data.charts[row_item.chart_index]]"></analyze-report-view>
                </div>
              </template>
            </template>
          </b-tab>
        </b-tabs>
      </template>
      <template v-else>
        <b-tabs>
          <b-tab :style="f_calculateStyleDashboardTabReports()">
            <template slot="title">
              <img src="@/icon/665778.png" style="width: 1.5em;">
              <span>Dataset</span>
            </template>
            <w-table2 :ref="'ref_' + p_reportData.index + '_' + p_reportData.table_index" v-if="d_showReportModule && p_reportData.table_index !== ''" :p_tableDetails="p_reportData.list[p_reportData.index].table_list[p_reportData.table_index]"></w-table2>
          </b-tab>
          <b-tab :style="f_calculateStyleDashboardTabReports()">
            <template slot="title">
              <img src="@/icon/665778.png" style="width: 1.5em;">
              <span>Grafik</span>
            </template>
            <b-card-header class="p-1">
              <b-row>
                <b-col cols="9"></b-col>
                <b-col cols="3">
                  <b-button variant="white" class="pull-right" size="md" @click="f_reportViewRecord(p_reportData.index, 'table', p_reportData.list[p_reportData.index].table_list[p_reportData.table_index].view, p_reportData.table_index)">
                    <img src="@/icon/629153.png" title="Kaydet" style="width: 2em;" /> Kaydet
                  </b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <template v-if="d_showReportModule && p_reportData.table_index !== '' && p_reportData.list[p_reportData.index].table_list[p_reportData.table_index].view && p_reportData.list[p_reportData.index].table_list[p_reportData.table_index].view.length > 0">
              <template v-for="(view, view_index) in p_reportData.list[p_reportData.index].table_list[p_reportData.table_index].view">
                <b-row style="margin: 3px; overflow-x: auto; overflow-y: auto; height: 400px; width: 1000px;">
                  <b-col cols="10">
                    <analyze-report-view v-if="view.type === 'chart' && view.charts !== undefined && view.charts.length > 0 && d_showReportModule" :p_analyzeReports="view.charts"></analyze-report-view>
                  </b-col>
                  <b-col cols="2">
                    <b-button id="del" size="sm" :variant="'white'" @click="f_deleteView(p_reportData.index, p_reportData.table_index, view_index)" title="sil">
                      <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </b-button>
                    <b-button id="edit" size="sm" :variant="'white'" @click="f_editView(p_reportData.index, p_reportData.table_index, view_index)" title="düzenle">
                      <img src="@/icon/3094236.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </template>
          </b-tab>
        </b-tabs>
        <!--         <div key="table_upper_button_list">
          <b-row>
            <b-col cols="12">
              <b-button id="dataset" size="sm" :variant="d_selectedTab ==='dataset' ? 'white' :'white'" :style="d_selectedTab ==='dataset' ? 'border: solid 1px green;' : ''" @click="f_selectViewMode('dataset')" title="Dataset Görünümü">
                <img src="@/icon/1936797.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Dataset
              </b-button>
              <b-button id="charts" size="sm" :variant="d_selectedTab ==='charts' ? 'white' :'white'" :style="d_selectedTab ==='charts' ? 'border: solid 1px green;' : ''" @click="f_selectViewMode('charts')" title="Grafikler">
                <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Grafik
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="d_selectedTab ==='dataset'">
          <w-table2 v-if="d_showReportModule && p_reportData.table_index !== ''" :p_tableDetails="p_reportData.list[p_reportData.index].table_list[p_reportData.table_index]"></w-table2>
        </div>
        <div v-if="d_selectedTab ==='charts'"></div>
 -->
      </template>
    </b-card>
    <modal v-if="p_reportList.show" @close="p_reportList.show = false" :width="'750'">
      <h3 slot="header">Rapor Listesi</h3>
      <div slot="body">
        <template v-for="(report, report_ind) in p_reportList.list">
          <b-row :style="p_reportList.index === report_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3);' : 'cursor: pointer; padding: 5px; border: 1px solid rgba(158, 193, 179, 0.3);'">
            <b-col sm="12" md="12" @click="f_selectThisreport(report_ind)">
              <strong>{{ report_ind + 1 }} -</strong>
              <img src="@/icon/2930471.png" style="width: 1.5em;" /> {{ report.name }},
              <!-- <img src="@/icon/9370.png" style="width: 1.5em;" /> {{ report.id }} -->
              <!-- <img v-if="report.ud" src="@/icon/2080590.png" style="width: 1.5em;" /> {{ DateFormat(report.ud) }} -->
            </b-col>
          </b-row>
        </template>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="p_reportList.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_chartCreate.show" @close="d_chartCreate.show = false" :width="'1000'">
      <h3 slot="header">Grafik Oluştur</h3>
      <div slot="body">
        <b-card no-body style="margin-bottom: 3px;">
          <b-card-header class="p-1">
            <b-row style="margin: 3px;">
              <b-col cols="4"><strong>X Değeri</strong></b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4"></b-col>
            </b-row>
          </b-card-header>
          <b-row style="margin: 3px;">
            <b-col cols="2"><strong>Veri Tipi</strong></b-col>
            <b-col cols="4">
              <b-form-select v-model="d_chartCreate.x_param_format">
                <b-form-select-option value="count">Adet</b-form-select-option>
                <b-form-select-option value="count_percent">Adet Yüzde</b-form-select-option>
                <b-form-select-option value="sum">Topla</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="6"></b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="4"><strong>Kolonlar</strong></b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
          </b-row>
          <b-row style="margin: 3px;" v-for="(x, x_ind) in d_chartCreate.x_columns">
            <b-col cols="2"></b-col>
            <b-col cols="6">
              <b-form-select v-model="x.value" @change="f_changeColumn('x', x_ind)">
                <b-form-select-option v-for="(col, col_index) in p_reportData.list[p_reportData.index].table_list[p_reportData.table_index].table_header" :value="col_index"> {{ col_index + 1 }} nolu kolon ( {{ col.label }} )</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="2">
              <b-form-input v-model="x.label" placeholder="Adı" @change="d_chartCreate.x1_style = {}"></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-button id="del" size="sm" :variant="'white'" @click="f_deleteColumn('x', x_ind)" title="sil">
                <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> sil
              </b-button>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="6"></b-col>
            <b-col cols="2">
              <b-button id="add" size="sm" :variant="'white'" @click="f_newColumn('x')" title="ekle">
                <img src="@/icon/2766716.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> ekle
              </b-button>
            </b-col>
            <b-col cols="4"></b-col>
          </b-row>
        </b-card>
        <b-card no-body style="margin-bottom: 3px;">
          <b-card-header class="p-1">
            <b-row style="margin: 3px;">
              <b-col cols="4"><strong>Y Değeri</strong></b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4"></b-col>
            </b-row>
          </b-card-header>
          <b-row style="margin: 3px;">
            <b-col cols="2"><strong>Veri Tipi</strong></b-col>
            <b-col cols="4">
              <b-form-select v-model="d_chartCreate.y_param_format">
                <b-form-select-option value="count">Adet</b-form-select-option>
                <b-form-select-option value="count_percent">Adet Yüzde</b-form-select-option>
                <b-form-select-option value="sum">Topla</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="6"></b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="4"><strong>Kolonlar</strong></b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
          </b-row>
          <b-row style="margin: 3px;" v-for="(y, y_ind) in d_chartCreate.y_columns">
            <b-col cols="2"></b-col>
            <b-col cols="6">
              <b-form-select v-model="y.value" @change="f_changeColumn('y', y_ind)">
                <b-form-select-option v-for="(col, col_index) in p_reportData.list[p_reportData.index].table_list[p_reportData.table_index].table_header" :value="col_index"> {{ col_index + 1 }} nolu kolon ( {{ col.label }} )</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="2">
              <b-form-input v-model="y.label" placeholder="Adı" @change="d_chartCreate.x2_style = {}"></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-button id="del" size="sm" :variant="'white'" @click="f_deleteColumn('y', y_ind)" title="sil">
                <img src="@/icon/2960590.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> sil
              </b-button>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="6"></b-col>
            <b-col cols="2">
              <b-button id="add" size="sm" :variant="'white'" @click="f_newColumn('y')" title="ekle">
                <img src="@/icon/2766716.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> ekle
              </b-button>
            </b-col>
            <b-col cols="4"></b-col>
          </b-row>
        </b-card>
        <b-card no-body style="margin-bottom: 3px;">
          <b-row style="margin: 3px;">
            <b-col cols="10">
              <strong style="color: skyblue;" v-if="d_chartCreate.dimension_x1">{{ d_chartCreate.dimension_x1 + ' X ' + d_chartCreate.dimension_y  }}</strong>
            </b-col>
            <b-col cols="2">
              <b-button id="analyze" size="sm" :variant="'white'" @click="f_createAnalyzeReport()" title="grafik oluştur">
                <img src="@/icon/986801.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> <strong style="color: skyblue;">Grafik Oluştur</strong>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body style="margin-bottom: 3px;">
          <b-row style="margin: 3px; overflow-x: auto; overflow-y: auto; height: 400px;">
            <b-col cols="12">
              <analyze-report-view v-if="d_chartCreate.charts.length > 0 && d_chartCreate.chart_show" :p_analyzeReports="d_chartCreate.charts"></analyze-report-view>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body style="margin-bottom: 3px;">
          <b-card-header class="p-1">
            <b-row style="margin: 3px;">
              <b-col cols="4"><strong>Özellik Seçimi</strong></b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4"></b-col>
            </b-row>
          </b-card-header>
          <b-row style="margin: 3px;">
            <b-col cols="4"><strong>Grafik Adı</strong></b-col>
            <b-col cols="8">
              <b-form-input v-model="d_chartCreate.label"></b-form-input>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="4"><strong>Grafik Bilgi</strong></b-col>
            <b-col cols="8">
              <b-textarea v-model="d_chartCreate.info"></b-textarea>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="4"><strong>Grafik Genişliği</strong></b-col>
            <b-col cols="8">
              <b-form-input v-model="d_chartCreate.chart_width" type="number"></b-form-input>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="4"><strong>Grafik Tipi</strong></b-col>
            <b-col cols="8">
              <b-form-select v-model="d_chartCreate.chart_type">
                <b-form-select-option value="bar">bar</b-form-select-option>
                <!-- <b-form-select-option value="line">çizgi</b-form-select-option> -->
                <!-- <b-form-select-option value="doughnut">halka</b-form-select-option> -->
                <!-- <b-form-select-option value="scatter">nokta</b-form-select-option> -->
                <!-- <b-form-select-option value="pie">pasta</b-form-select-option> -->
              </b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="4"><strong>Anasayfada Göster</strong></b-col>
            <b-col cols="8">
              <b-form-select v-model="d_chartCreate.show_in_dashboard">
                <b-form-select-option value="yes">evet</b-form-select-option>
                <b-form-select-option value="no">hayır</b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col cols="4"><strong>Sıralama</strong></b-col>
            <b-col cols="8">
              <b-form-select v-model="d_chartCreate.order_x">
                <b-form-select-option value="numeric_small_to_big">küçükten büyüğe</b-form-select-option>
                <b-form-select-option value="numeric_big_to_small">büyükten küçüğe</b-form-select-option>
                <b-form-select-option value="alphabetic_begin_to_end">A - Z</b-form-select-option>
                <b-form-select-option value="alphabetic_end_to_begin">Z - A</b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;" v-if="Object.keys(d_chartCreate.x1_style).length > 0">
            <b-col cols="4"><strong>Renkler</strong></b-col>
            <b-col cols="8">
              <div style="overflow-x: hidden; overflow-y: auto; height: 200px;">
                <template v-for="(x1, x1_index) in d_chartCreate.x1_style">
                  <b-row>
                    <b-col cols="2">{{ x1_index }}</b-col>
                    <b-col cols="3">
                      <b-form-select v-model="x1.backgroundColor.r" @change="$forceUpdate()">
                        <b-form-select-option :value="0">0</b-form-select-option>
                        <b-form-select-option v-for="x in 255" :value="x">{{ x }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="3">
                      <b-form-select v-model="x1.backgroundColor.g" @change="$forceUpdate()">
                        <b-form-select-option :value="0">0</b-form-select-option>
                        <b-form-select-option v-for="x in 255" :value="x">{{ x }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="3">
                      <b-form-select v-model="x1.backgroundColor.b" @change="$forceUpdate()">
                        <b-form-select-option :value="0">0</b-form-select-option>
                        <b-form-select-option v-for="x in 255" :value="x">{{ x }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="1">
                      <div :style="'height: 10px; width: 10px; background-color: rgb(' + x1.backgroundColor.r + ', ' + x1.backgroundColor.g + ', ' + x1.backgroundColor.b + ')'"></div>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;" v-if="Object.keys(d_chartCreate.x2_style).length > 0">
            <b-col cols="4"><strong>Renkler</strong></b-col>
            <b-col cols="8">
              <div style="overflow-x: hidden; overflow-y: auto; height: 200px;">
                <template v-for="(x2, x2_index) in d_chartCreate.x2_style">
                  <b-row>
                    <b-col cols="2">{{ x2_index }}</b-col>
                    <b-col cols="3">
                      <b-form-select v-model="x2.backgroundColor.r" @change="$forceUpdate()">
                        <b-form-select-option :value="0">0</b-form-select-option>
                        <b-form-select-option v-for="x in 255" :value="x">{{ x }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="3">
                      <b-form-select v-model="x2.backgroundColor.g" @change="$forceUpdate()">
                        <b-form-select-option :value="0">0</b-form-select-option>
                        <b-form-select-option v-for="x in 255" :value="x">{{ x }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="3">
                      <b-form-select v-model="x2.backgroundColor.b" @change="$forceUpdate()">
                        <b-form-select-option :value="0">0</b-form-select-option>
                        <b-form-select-option v-for="x in 255" :value="x">{{ x }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="1">
                      <div :style="'height: 10px; width: 10px; background-color: rgb(' + x2.backgroundColor.r + ', ' + x2.backgroundColor.g + ', ' + x2.backgroundColor.b + ')'"></div>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="d_chartCreate.show_in_dashboard === 'yes'">
            <b-col cols="4"><strong>Anasayfa Grup Adı</strong></b-col>
            <b-col cols="8">
              <b-form-input v-model="d_chartCreate.dashboard_groupname"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body style="margin-bottom: 3px;">
          <b-row style="margin: 3px;">
            <b-col cols="8"></b-col>
            <b-col cols="4">
              <b-button id="analyze" size="sm" :variant="'white'" @click="f_addToViewList('chart')" title="grafik ekle">
                <img src="@/icon/2766716.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Grafik Listesine Ekle
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_chartCreate.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as Modal
} from "@/components/widgets/Modal";

import {
  default as WTable2
} from '@/components/widgets/WTable2';
import { mapGetters } from 'vuex';
import {
  default as AnalyzeReportView
} from "@/components/widgets/AnalyzeReportView";
import moment from 'moment';

export default {
  name: 'ReportModule',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_reportData: {
      type: Object,
      required: true
    },
    p_reportDashboard: {
      type: Object,
      required: false
    },
    p_reportList: {
      type: Object,
      required: true
    },
    f_selectThisreport: {
      type: Function,
      required: true
    },
    f_reportViewRecord: {
      type: Function,
      required: false
    },
  },
  components: {
    AnalyzeReportView,
    WTable2,
    Modal,
  },
  data () {
    return {
      d_searchViewList: [],
      d_searchDashboardText: '',
      d_dashboardRows: {
        'dashboard_group': [],
        'other': [],
      },
      d_chartCreate: {
        'chart_show': false,
        'chart_type': 'bar',
        'chart_width': 500,
        'charts': [],
        'dashboard_groupname': '',
        'default_backgroundColor': 'rgb(0, 184, 230)',
        'dimension_x1': 0,
        'dimension_x2': 0,
        'dimension_y': 0,
        'info': 'Bu grafik ... göstermektedir.',
        'label': 'Yeni Grafik',
        'order_x': 'numeric_big_to_small',
        'show': false,
        'show_in_dashboard': 'no',
        'widht_for_every_bar': 20,
        'x1_style': {},
        'x2_style': {},
        'x_columns': [],
        'x_param_format': 'count',
        'y_columns': [],
        'y_param_format': 'count',
      },
      d_selectedTab: 'dataset',
      d_showReportModule: false,
      d_fullScreenGeneral: true,
    };
  },
  created: function () {},
  mounted: function () {},
  destroyed () {},
  methods: {
    f_createNewChart: function () {
      this.d_chartCreate.chart_show = false;
      this.d_chartCreate.table_header = [];
      this.d_chartCreate.chart_type = 'bar';
      this.d_chartCreate.chart_width = 500;
      this.d_chartCreate.charts = [];
      this.d_chartCreate.dashboard_groupname = '';
      this.d_chartCreate.dimension_x1 = 0;
      this.d_chartCreate.dimension_x2 = 0;
      this.d_chartCreate.dimension_y = 0;
      this.d_chartCreate.info = 'Bu grafik ... göstermektedir.';
      this.d_chartCreate.label = 'Yeni Grafik';
      this.d_chartCreate.order_x = 'numeric_big_to_small';
      this.d_chartCreate.show_in_dashboard = 'yes';
      this.d_chartCreate.widht_for_every_bar = 25;
      this.d_chartCreate.x1_style = {};
      this.d_chartCreate.x2_style = {};
      this.d_chartCreate.x_columns = [];
      this.d_chartCreate.x_param_format = 'count';
      this.d_chartCreate.y_columns = [];
      this.d_chartCreate.y_param_format = 'count';
      this.d_chartCreate.process = 'new';
      try {
        this.$delete(this.d_chartCreate, 'edit_report_index');
      } catch (err) {}
      try {
        this.$delete(this.d_chartCreate, 'edit_table_index');
      } catch (err) {}
      try {
        this.$delete(this.d_chartCreate, 'edit_view_index');
      } catch (err) {}
      this.d_chartCreate.show = true;
    },
    f_calculateCols: function (row_item) {
      let style = 'float: left; height: 400px; overflow-x: auto; overflow-y: auto;';
      let screen_width = window.innerWidth;
      let target_width = '';
      if (screen_width < (row_item.width + 100)) {
        target_width = screen_width - 250;
      } else {
        target_width = row_item.width + 100;
      }
      style += 'width: ' + (target_width).toString();
      // style += 'width: ' + (row_item.width + 100).toString();
      style += 'px;';
      // console.log('style : ', style);
      return style;
    },
    f_createDashboardRows: function () {
      // console.log('this.p_reportDashboard : ', this.p_reportDashboard);
      let added_report_list = [];
      let screen_width = window.innerWidth;
      if (this.p_reportDashboard && this.p_reportDashboard.dashboard_group && this.p_reportDashboard.dashboard_group.order.length > 0) {
        for (let d_group_ind in this.p_reportDashboard.dashboard_group.order) {
          let dashboard_caption = this.p_reportDashboard.dashboard_group.order[d_group_ind];
          // console.log('dashboard_caption : ', dashboard_caption);
          if (this.p_reportDashboard.dashboard_group.list[dashboard_caption])
            for (let view_index in this.p_reportDashboard.dashboard_group.list[dashboard_caption].list) {
              // console.log('view_index : ', view_index);
              let view_item = this.p_reportDashboard.dashboard_group.list[dashboard_caption].list[view_index];
              // console.log('view_item : ', view_item);
              if (view_item.data.charts !== undefined && view_item.data.charts.length > 0) {
                for (let chart_index in view_item.data.charts) {
                  // console.log('chart_index : ', chart_index);
                  let new_row_item = {
                    'caption': dashboard_caption,
                    'view_index': parseInt(view_index),
                    'chart_index': parseInt(chart_index),
                    'width': screen_width,
                  };
                  let chart_data = view_item.data.charts[chart_index];
                  let chart_width = screen_width;
                  try {
                    chart_width = parseInt(chart_data.data.general.chart_width.val);
                  } catch (err) {}
                  new_row_item.width = chart_width;
                  if (this.d_searchDashboardText) {
                    let chart_label = '';
                    try {
                      chart_label = chart_data.label;
                    } catch (err) {}
                    if (chart_label) {
                      if (added_report_list.indexOf(JSON.stringify(new_row_item)) === -1) {
                        added_report_list.push(JSON.stringify(new_row_item));
                        if (chart_label.toLocaleLowerCase().indexOf(this.d_searchDashboardText.toLocaleLowerCase()) !== -1) {
                          this.d_searchViewList.push(new_row_item);
                        }
                      }
                    }
                  } else {
                    if (this.d_dashboardRows.dashboard_group.length === 0) {
                      let a_row = { 'list': [] };
                      this.d_dashboardRows.dashboard_group.push(a_row);
                    }
                    let row_width_total = 0;
                    for (let row_li_ind in this.d_dashboardRows.dashboard_group[this.d_dashboardRows.dashboard_group.length - 1].list) {
                      row_width_total += this.d_dashboardRows.dashboard_group[this.d_dashboardRows.dashboard_group.length - 1].list[row_li_ind].width;
                    }
                    let remaining_width = screen_width - row_width_total;
                    if (chart_width <= remaining_width) {
                      this.d_dashboardRows.dashboard_group[this.d_dashboardRows.dashboard_group.length - 1].list.push(new_row_item);
                    } else {
                      let a_row = { 'list': [] };
                      this.d_dashboardRows.dashboard_group.push(a_row);
                      this.d_dashboardRows.dashboard_group[this.d_dashboardRows.dashboard_group.length - 1].list.push(new_row_item);
                    }
                  }
                }
              }
            }
        }
      }
      // console.log('this.d_dashboardRows : ', this.d_dashboardRows);
    },
    f_changeColumn: function (column_type, column_index) {
      if (column_type === 'x') {
        let col_value = this.d_chartCreate.x_columns[column_index]['value'];
        this.d_chartCreate.x_columns[column_index]['label'] = this.p_reportData.list[this.p_reportData.index].table_list[this.p_reportData.table_index].table_header[col_value]['label'];
      } else if (column_type === 'y') {
        let col_value = this.d_chartCreate.y_columns[column_index]['value'];
        this.d_chartCreate.y_columns[column_index]['label'] = this.p_reportData.list[this.p_reportData.index].table_list[this.p_reportData.table_index].table_header[col_value]['label'];
      }
      this.f_resetCharts();
    },
    f_calculateStyleDashboardTab: function (view_type) {
      let style = '';
      if (this.d_fullScreenGeneral) {
        let top_height = 50;
        style = 'height:' + (window.innerHeight - top_height) + 'px; overflow-x: hidden; overflow-y: auto;';
      } else {
        let top_height = 165;
        style = 'height:' + (window.innerHeight - top_height) + 'px; overflow-x: hidden; overflow-y: auto;';
      }
      return style;
    },
    f_calculateStyleDashboardTabReports: function (view_type) {
      let style = '';
      if (this.d_fullScreenGeneral) {
        let top_height = 70;
        style = 'height:' + (window.innerHeight - top_height) + 'px; overflow-x: hidden; overflow-y: auto;';
      } else {
        let top_height = 175;
        style = 'height:' + (window.innerHeight - top_height) + 'px; overflow-x: hidden; overflow-y: auto;';
      }
      return style;
    },
    f_addToViewList: function (view_type) {
      this.d_showReportModule = false;
      let new_view_data = {
        'type': view_type,
        'show_in_dashboard': this.d_chartCreate.show_in_dashboard,
        'dashboard_groupname': this.d_chartCreate.dashboard_groupname,
      };
      if (view_type === 'chart') {
        let table_data = this.p_reportData.list[this.p_reportData.index].table_list[this.p_reportData.table_index];
        new_view_data.chart_create = {
          'label': this.d_chartCreate.label,
          'info': this.d_chartCreate.info,
          'show_in_dashboard': this.d_chartCreate.show_in_dashboard,
          'dashboard_groupname': this.d_chartCreate.dashboard_groupname,
          'chart_type': this.d_chartCreate.chart_type,
          'y_param_format': this.d_chartCreate.y_param_format,
          'x_param_format': this.d_chartCreate.x_param_format,
          'x_columns': this.d_chartCreate.x_columns,
          'y_columns': this.d_chartCreate.y_columns,
          'order_x': this.d_chartCreate.order_x,
          'x1_style': {},
          'x2_style': {},
          'table_header': [],
          'chart_width': this.d_chartCreate.chart_width,
          'widht_for_every_bar': this.d_chartCreate.widht_for_every_bar,
        };
        let new_table_header = [];
        for (let i in table_data.table_header) {
          let new_table_header_col = { 'selected_options': [] };
          if (table_data.table_header[i].selected_options.length > 0) {
            new_table_header_col.selected_options = table_data.table_header[i].selected_options;
          }
          new_table_header.push(new_table_header_col);
        }
        new_view_data.chart_create.table_header = new_table_header;
        // console.log('table_data.table_header : ', table_data.table_header);
        let target_x1_style = {};
        // console.log('this.d_chartCreate.x1_style : ', this.d_chartCreate.x1_style);
        for (let i in this.d_chartCreate.x1_style) {
          if (this.d_chartCreate.x1_style[i].backgroundColor.r === 0 && this.d_chartCreate.x1_style[i].backgroundColor.g === 184 && this.d_chartCreate.x1_style[i].backgroundColor.b === 230) {
            // No change for this style.
          } else {
            target_x1_style[i] = this.d_chartCreate.x1_style[i];
          }
        }
        // console.log('target_x1_style : ', target_x1_style);
        new_view_data.chart_create.x1_style = target_x1_style;

        let target_x2_style = {};
        for (let i in this.d_chartCreate.x2_style) {
          if (this.d_chartCreate.x2_style[i].backgroundColor.r === 0 && this.d_chartCreate.x2_style[i].backgroundColor.g === 184 && this.d_chartCreate.x2_style[i].backgroundColor.b === 230) {
            // No change for this style.
          } else {
            target_x2_style[i] = this.d_chartCreate.x2_style[i];
          }
        }
        new_view_data.chart_create.x2_style = target_x2_style;

        new_view_data.charts = this.d_chartCreate.charts;
        if (table_data.view === undefined) {
          table_data.view = [];
        }
        let alert_txt = '';
        if (this.d_chartCreate.process === 'edit') {
          table_data.view[this.d_chartCreate.edit_view_index] = new_view_data;
          alert_txt = 'güncellendi';
        } else {
          alert_txt = 'eklendi';
          table_data.view.push(new_view_data);
        }
        setTimeout(function () {
          this.d_showReportModule = true;
          alert(alert_txt);
        }.bind(this), 50);

        /*
          if (this.d_chartCreate.edit_report_index !== undefined && this.d_chartCreate.edit_table_index !== undefined && this.d_chartCreate.edit_view_index !== undefined) {
          } else {
          }
        */
        // console.log('new_view_data : ', new_view_data);
      }
    },
    f_createAnalyzeReport: function () {
      // console.log('q347947 this.d_chartCreate.chart_show ', this.d_chartCreate.chart_show);
      this.d_chartCreate.chart_show = false;
      // this.d_chartCreate.x2_style = {};
      this.d_chartCreate.charts = [];
      let new_analyze_report = {
        'date': '',
        'label': this.d_chartCreate.label,
        "data": {
          "general": {
            "info": { "val": this.d_chartCreate.label },
            "chart_data_dataset": { "list": [] },
            "chart_data_label": { "list": [] }, // chart_data_label_info => {'chart_data_label_info': {'val': ' y values'}}
            "report_name_manuel": { "val": this.d_chartCreate.label },
            "chart_data_dataset_background_color_type": { "val": { "value": "auto", "label": "otomatik" } },
            "report_type": { "val": { "value": "chart", "label": "grafik" } },
            "chart_type": { "val": { "value": this.d_chartCreate.chart_type, "label": this.d_chartCreate.chart_type } },
            "chart_width": { "val": this.d_chartCreate.chart_width },
          }
        }
      };
      let table_data = this.p_reportData.list[this.p_reportData.index].table_list[this.p_reportData.table_index];
      if (table_data.ud) {
        new_analyze_report.date = table_data.ud;
      }
      // console.log('this.d_chartCreate : ', this.d_chartCreate);
      if (this.d_chartCreate.chart_type === 'bar') {
        let prepare_type = '2';
        let cell_value_list = this.f_getColumnCellValueList(prepare_type, this.d_chartCreate.x_columns, this.d_chartCreate.y_columns, this.d_chartCreate.x_param_format, this.d_chartCreate.y_param_format, this.d_chartCreate.chart_type);
        // console.log('cell_value_list : ', cell_value_list);

        // x values must be categorical always.
        // x values must be maximum two values. Then the fist x value is the main x value, the other x values are merged and add as child to x1 first layer.
        // y values may be categorical or numeric. If more than 1 y values present, they must be two numeric or categorical for all.
        // Examples:
        // One x column -> y values frequency.
        // One x column -> y numeric value.
        /*
          cell_value_list = {
            'x1': { 'xx1': 10, 'xx2': 5 },
            'x2': { 'xx1': 3, 'xx2': 13 },
          };
        */
        // One x column -> x value frequency.
        // One x column ->  one y numeric value.
        /*
          cell_value_list = {
            'x1': 10,
            'x2': 5,
          };
        */
        if (Object.keys(cell_value_list).length > 0) {
          let x2_value_list = [];
          if (this.d_chartCreate.x_columns.length === 1) {
            if (this.d_chartCreate.y_columns.length === 0) {
              let order_x1_list = this.f_prepareOrderX1List(cell_value_list, 'x1');
              this.f_prepareChartWdmr(cell_value_list, order_x1_list, '1', new_analyze_report, 'y');
            } else if (this.d_chartCreate.y_columns.length === 1) {
              if (this.d_chartCreate.y_param_format === 'sum') {
                let order_x1_list = this.f_prepareOrderX1List(cell_value_list, 'x1');
                // console.log('order_x1_list : ', order_x1_list);
                this.f_prepareChartWdmr(cell_value_list, order_x1_list, '1', new_analyze_report, 'y');
              } else if (this.d_chartCreate.y_param_format === 'count' || this.d_chartCreate.y_param_format === 'count_percent') {
                let order_x1_list = this.f_prepareOrderX1List(cell_value_list, 'x2');
                this.f_prepareChartWdmr(cell_value_list, order_x1_list, '2', new_analyze_report, '');
              }
            } else if (this.d_chartCreate.y_columns.length > 1) {
              if (this.d_chartCreate.y_param_format === 'sum' || this.d_chartCreate.y_param_format === 'count' || this.d_chartCreate.y_param_format === 'count_percent') {
                let order_x1_list = this.f_prepareOrderX1List(cell_value_list, 'x2');
                this.f_prepareChartWdmr(cell_value_list, order_x1_list, '2', new_analyze_report, '');
              }
            }
          } else if (this.d_chartCreate.x_columns.length > 1) {
            if (this.d_chartCreate.y_columns.length === 0) {
              let order_x1_list = this.f_prepareOrderX1List(cell_value_list, 'x1');
              this.f_prepareChartWdmr(cell_value_list, order_x1_list, '1', new_analyze_report, 'y');
            } else if (this.d_chartCreate.y_columns.length === 1) {
              let order_x1_list = this.f_prepareOrderX1List(cell_value_list, 'x2');
              this.f_prepareChartWdmr(cell_value_list, order_x1_list, '2', new_analyze_report, '');
            }
          }
          // this.d_chartCreate.dimension_x1 = Object.keys(cell_value_list).length;
          try {
            this.d_chartCreate.dimension_x1 = new_analyze_report.data.general.chart_data_label.list.length;
          } catch (err) {}
          try {
            this.d_chartCreate.dimension_y = new_analyze_report.data.general.chart_data_dataset.list.length;
          } catch (err) {}
          let widht_for_every_bar = 20;
          let size = this.d_chartCreate.dimension_x1 * this.d_chartCreate.dimension_y;
          /*
          console.log('this.d_chartCreate.dimension_x1 : ', this.d_chartCreate.dimension_x1);
          console.log('this.d_chartCreate.dimension_y  : ', this.d_chartCreate.dimension_y);
          console.log('this.d_chartCreate.chart_width  : ', this.d_chartCreate.chart_width);
          */
          if (size < 1000) {
            this.d_chartCreate.chart_width = Math.round(size * this.d_chartCreate.widht_for_every_bar);
            if (this.d_chartCreate.chart_width < 250) {
              this.d_chartCreate.chart_width = 250;
            }
            // console.log('new_analyze_report ', new_analyze_report);
            new_analyze_report.data.general.chart_width.val = this.d_chartCreate.chart_width;
            this.d_chartCreate.charts.push(new_analyze_report);
            // console.log('this.d_chartCreate.chart_show : ', this.d_chartCreate.chart_show);
            setTimeout(function () {
              this.d_chartCreate.chart_show = true;
              // console.log('this.d_chartCreate.chart_show : ', this.d_chartCreate.chart_show);
            }.bind(this), 50);
          } else {
            new_analyze_report = '';
            setTimeout(function () {
              alert('Grafik boyutu çok büyük. Lütfen kontrol ediniz!!' + size);
            }.bind(this), 50);
          }
        }
      }
    },
    f_prepareChartWdmr: function (cell_value_list, order_x1_list, process_type, new_analyze_report, label_type) {
      if (process_type === '1') {
        let use_label = '';
        let x_string_list = [];
        for (let x_index in this.d_chartCreate.x_columns) {
          x_string_list.push(this.d_chartCreate.x_columns[x_index]['label']);
        }
        if (x_string_list.length > 0) {
          use_label += x_string_list.join('-');
        }
        if (use_label) {
          use_label += ' & ';
        }
        let y_string_list = [];
        for (let y_index in this.d_chartCreate.y_columns) {
          y_string_list.push(this.d_chartCreate.y_columns[y_index]['label']);
        }
        if (y_string_list.length > 0) {
          use_label += y_string_list.join('-');
        }
        let chart_data_dataset = {
          "chart_data_dataset_label": { "val": use_label },
          "chart_data_dataset_data": { "list": [] }
        }
        new_analyze_report.data.general.chart_data_dataset.list.push(chart_data_dataset);
        for (let x1_index in order_x1_list) {
          let x1 = order_x1_list[x1_index];
          if (cell_value_list[x1] !== undefined) {
            // console.log('x1 : ', x1);
            let chart_data_label_info = { 'chart_data_label_info': { 'val': x1 } };
            new_analyze_report.data.general.chart_data_label.list.push(chart_data_label_info);
            let new_chart_dataset_data = {
              "chart_data_dataset_data_value_background_color": { "val": this.d_chartCreate.default_backgroundColor },
              "chart_data_dataset_data_value": { "val": "" }
            };
            if (this.d_chartCreate.x1_style[x1] !== undefined) {
              new_chart_dataset_data.chart_data_dataset_data_value_background_color = {
                'val': 'rgb(' + this.d_chartCreate.x1_style[x1].backgroundColor.r + ', ' + this.d_chartCreate.x1_style[x1].backgroundColor.g + ', ' + this.d_chartCreate.x1_style[x1].backgroundColor.b + ')'
              }
            } else {
              this.d_chartCreate.x1_style[x1] = {
                'backgroundColor': {
                  'r': 0,
                  'g': 184,
                  'b': 230,
                }
              }
            }
            new_chart_dataset_data.chart_data_dataset_data_value.val = cell_value_list[x1]['result'];
            new_analyze_report.data.general.chart_data_dataset.list[0].chart_data_dataset_data.list.push(new_chart_dataset_data);
          }
        }
      } else if (process_type === '2') {
        let x2_value_list = [];
        let x2_value_list_numeric = [];
        for (let x1_index in order_x1_list) {
          let x1 = order_x1_list[x1_index];
          for (let x2 in cell_value_list[x1]) {
            if (x2_value_list.indexOf(x2) === -1) {
              x2_value_list.push(x2);
            }
            try {
              if (x2_value_list_numeric.indexOf(parseInt(x2)) === -1) {
                x2_value_list_numeric.push(parseInt(x2));
              }
            } catch (err) {}
          }
        }
        // console.log('x2_value_list         : ', x2_value_list);
        // console.log('x2_value_list_numeric : ', x2_value_list_numeric);
        /*
          if (x2_value_list_numeric.length === x2_value_list.length) {
            x2_value_list = x2_value_list_numeric;
          }
          x2_value_list.sort();
        */
        // console.log('x2_value_list ', x2_value_list);
        for (let x2_index in x2_value_list) {
          let x2 = x2_value_list[x2_index];
          let chart_data_dataset = {
            "chart_data_dataset_label": { "val": x2 },
            "chart_data_dataset_data": { "list": [] }
          }
          new_analyze_report.data.general.chart_data_dataset.list.push(chart_data_dataset);
        }
        // console.log('order_x1_list ', order_x1_list);
        for (let x1_index in order_x1_list) {
          let x1 = order_x1_list[x1_index];
          let chart_data_label_info = { 'chart_data_label_info': { 'val': x1 } };
          new_analyze_report.data.general.chart_data_label.list.push(chart_data_label_info);
          for (let x2_index in x2_value_list) {
            let x2 = x2_value_list[x2_index];
            if (cell_value_list[x1][x2] !== undefined) {
              let new_chart_dataset_data = {
                "chart_data_dataset_data_value_background_color": { "val": this.d_chartCreate.default_backgroundColor },
                "chart_data_dataset_data_value": { "val": "" }
              };
              // console.log('this.d_chartCreate.x2_style[x2] : ', this.d_chartCreate.x2_style[x2]);
              if (this.d_chartCreate.x2_style[x2] !== undefined) {
                new_chart_dataset_data.chart_data_dataset_data_value_background_color = {
                  'val': 'rgb(' + this.d_chartCreate.x2_style[x2].backgroundColor.r + ', ' + this.d_chartCreate.x2_style[x2].backgroundColor.g + ', ' + this.d_chartCreate.x2_style[x2].backgroundColor.b + ')'
                }
              } else {
                this.d_chartCreate.x2_style[x2] = {
                  'backgroundColor': {
                    'r': 0,
                    'g': 184,
                    'b': 230,
                  }
                }
              }
              new_chart_dataset_data.chart_data_dataset_data_value.val = cell_value_list[x1][x2]['result'];
              new_analyze_report.data.general.chart_data_dataset.list[x2_index].chart_data_dataset_data.list.push(new_chart_dataset_data);
            }
          }
        }
      }
    },
    f_prepareOrderX1List: function (cell_value_list, sort_type = 'x1') {
      let order_x1_list = [];
      if (this.d_chartCreate.order_x === 'numeric_big_to_small') {
        if (sort_type === 'x1') {
          let object_list = [];
          for (let c_val in cell_value_list) {
            let obj = { 'label': c_val, 'value': cell_value_list[c_val]['result'] };
            object_list.push(obj);
          }
          let asc_or_desc = -1;
          let param = 'value';

          function compare(a, b) {
            // Use toUpperCase() to ignore character casing
            const param_a = a[param];
            const param_b = b[param];
            let comparison = 0;
            if (param_a > param_b) {
              comparison = 1;
            } else if (param_a < param_b) {
              comparison = -1;
            }
            return comparison * asc_or_desc;
          }
          object_list.sort(compare);
          for (let obj_index in object_list) {
            order_x1_list.push(object_list[obj_index]['label']);
          }
        } else if (sort_type === 'x2') {
          let object_list = [];
          for (let c_val_1 in cell_value_list) {
            let total_count = 0;
            for (let c_val_2 in cell_value_list[c_val_1]) {
              if (cell_value_list[c_val_1][c_val_2]['result']) {
                total_count += cell_value_list[c_val_1][c_val_2]['result']
              }
            }
            let obj = { 'label': c_val_1, 'value': total_count };
            object_list.push(obj);
          }
          let asc_or_desc = -1;
          let param = 'value';

          function compare(a, b) {
            // Use toUpperCase() to ignore character casing
            const param_a = a[param];
            const param_b = b[param];
            let comparison = 0;
            if (param_a > param_b) {
              comparison = 1;
            } else if (param_a < param_b) {
              comparison = -1;
            }
            return comparison * asc_or_desc;
          }
          object_list.sort(compare);
          for (let obj_index in object_list) {
            order_x1_list.push(object_list[obj_index]['label']);
          }
        }
      } else if (this.d_chartCreate.order_x === 'numeric_small_to_big') {
        if (sort_type === 'x1') {
          let object_list = [];
          for (let c_val in cell_value_list) {
            let obj = { 'label': c_val, 'value': cell_value_list[c_val]['result'] };
            object_list.push(obj);
          }
          let asc_or_desc = 1;
          let param = 'value';

          function compare(a, b) {
            // Use toUpperCase() to ignore character casing
            const param_a = a[param];
            const param_b = b[param];
            let comparison = 0;
            if (param_a > param_b) {
              comparison = 1;
            } else if (param_a < param_b) {
              comparison = -1;
            }
            return comparison * asc_or_desc;
          }
          object_list.sort(compare);
          for (let obj_index in object_list) {
            order_x1_list.push(object_list[obj_index]['label']);
          }
        } else if (sort_type === 'x2') {
          let object_list = [];
          for (let c_val_1 in cell_value_list) {
            let total_count = 0;
            for (let c_val_2 in cell_value_list[c_val_1]) {
              if (cell_value_list[c_val_1][c_val_2]['result']) {
                total_count += cell_value_list[c_val_1][c_val_2]['result']
              }
            }
            let obj = { 'label': c_val_1, 'value': total_count };
            object_list.push(obj);
          }
          let asc_or_desc = 1;
          let param = 'value';

          function compare(a, b) {
            // Use toUpperCase() to ignore character casing
            const param_a = a[param];
            const param_b = b[param];
            let comparison = 0;
            if (param_a > param_b) {
              comparison = 1;
            } else if (param_a < param_b) {
              comparison = -1;
            }
            return comparison * asc_or_desc;
          }
          object_list.sort(compare);
          for (let obj_index in object_list) {
            order_x1_list.push(object_list[obj_index]['label']);
          }
        }
      } else if (this.d_chartCreate.order_x === 'alphabetic_begin_to_end') {
        order_x1_list = Object.keys(cell_value_list).sort();
      } else if (this.d_chartCreate.order_x === 'alphabetic_end_to_begin') {
        order_x1_list = Object.keys(cell_value_list).sort().reverse();
      }
      return order_x1_list;
    },
    f_getColumnCellValueList: function (prepare_type, x_columns, y_columns, x_param_format, y_param_format, chart_type) {
      /*
        console.log('chart_type     : ', chart_type);
        console.log('prepare_type   : ', prepare_type);
        console.log('x_param_format : ', x_param_format);
        console.log('y_param_format : ', y_param_format);
        console.log('x_columns      : ', x_columns);
      */
      let is_eligible = true;
      let cell_value_list_1 = [];
      let cell_value_list_2 = {};
      let combination_count = 0;
      let all_total = 0;
      let table_data = this.p_reportData.list[this.p_reportData.index].table_list[this.p_reportData.table_index];
      let is_there_selected_header_option = false;
      for (let header_op_index in table_data.table_header) {
        let table_header_col = table_data.table_header[header_op_index];
        if (table_header_col.selected_options && table_header_col.selected_options.length > 0) {
          is_there_selected_header_option = true;
          break;
        }
      }
      for (let row_index in table_data.table) {
        let det = true;
        if (is_there_selected_header_option) {
          for (let caption_index in table_data.table_header) {
            let caption_cell = table_data.table_header[caption_index];
            let active_cell = table_data.table[row_index][caption_index];
            if (caption_cell.selected_options.length > 0) {
              if (active_cell.val && active_cell.val.label) {
                if (caption_cell.selected_options.indexOf(active_cell.val.label) === -1) {
                  det = false;
                  break;
                }
              } else {
                det = false;
                break;
              }
            }
          }
        }
        if (det) {
          if (prepare_type === '1') {
            let x_val_list = [];
            let y_val_list = [];
            for (let c_index in x_columns) {
              let column_index = parseInt(x_columns[c_index]['value']);
              // console.log('column_index : ', column_index);
              // console.log('table_data.table[row_index] : ', table_data.table[row_index]);
              let cell_data = table_data.table[row_index][column_index];
              // console.log('cell_data : ', cell_data);
              let cell_val = '';
              if (cell_data.val && cell_data.val.label) {
                cell_val = cell_data.val.label;
              }
              x_val_list.push(cell_val);
            }
            for (let c_index in y_columns) {
              let column_index = parseInt(y_columns[c_index]['value']);
              let cell_data = table_data.table[row_index][column_index];
              let cell_val = '';
              if (cell_data.val && cell_data.val.label) {
                cell_val = cell_data.val.label;
              }
              y_val_list.push(cell_val);
            }
            cell_value_list_1.push({ 'x': x_val_list, 'y': y_val_list });
          } else if (prepare_type === '2') {
            if (chart_type === 'bar') {
              if (x_columns.length === 1) {
                // Y control......
                if (y_columns.length === 0) {
                  if (x_param_format === 'count') {
                    let column_index = parseInt(x_columns[0]['value']);
                    // console.log('column_index : ', column_index);
                    // console.log('table_data.table[row_index] : ', table_data.table[row_index]);
                    let cell_data = table_data.table[row_index][column_index];
                    if (cell_data.val && cell_data.val.label) {
                      let cell_val = cell_data.val.label;
                      if (cell_value_list_2[cell_val] === undefined) {
                        cell_value_list_2[cell_val] = { 'count': 0 };
                      }
                      cell_value_list_2[cell_val]['count'] += 1;
                      cell_value_list_2[cell_val]['result'] = cell_value_list_2[cell_val]['count'];
                    }
                  } else if (x_param_format === 'count_percent') {
                    let column_index = parseInt(x_columns[0]['value']);
                    // console.log('column_index : ', column_index);
                    // console.log('table_data.table[row_index] : ', table_data.table[row_index]);
                    let cell_data = table_data.table[row_index][column_index];
                    if (cell_data.val && cell_data.val.label) {
                      all_total += 1;
                      let cell_val = cell_data.val.label;
                      if (cell_value_list_2[cell_val] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[cell_val] = { 'count': 0 };
                      }
                      // console.log('all_total : ', all_total);
                      cell_value_list_2[cell_val]['count'] += 1;
                    }
                    for (let ccc in cell_value_list_2) {
                      cell_value_list_2[ccc]['result'] = Math.round((cell_value_list_2[ccc]['count'] / all_total) * 100);
                    }
                  }
                } else if (y_columns.length === 1) {
                  let x_column_index = parseInt(x_columns[0]['value']);
                  let y_column_index = parseInt(y_columns[0]['value']);
                  let cell_data_x = table_data.table[row_index][x_column_index];
                  let cell_data_y = table_data.table[row_index][y_column_index];
                  // console.log('cell_data_x  :', cell_data_x);
                  // console.log('cell_data_y  :', cell_data_y);
                  if (y_param_format === 'count') {
                    if (cell_data_x.val && cell_data_x.val.label) {
                      let cell_val_x = cell_data_x.val.label;
                      if (cell_value_list_2[cell_val_x] === undefined) {
                        cell_value_list_2[cell_val_x] = {};
                      }
                      if (cell_data_y.val && cell_data_y.val.label) {
                        let cell_val_y = cell_data_y.val.label;
                        if (cell_value_list_2[cell_val_x][cell_val_y] === undefined) {
                          combination_count += 1;
                          cell_value_list_2[cell_val_x][cell_val_y] = { 'count': 0 };
                        }
                        cell_value_list_2[cell_val_x][cell_val_y]['count'] += 1;
                        cell_value_list_2[cell_val_x][cell_val_y]['result'] = cell_value_list_2[cell_val_x][cell_val_y]['count'];
                      }
                    }
                  } else if (y_param_format === 'count_percent') {
                    if (cell_data_x.val && cell_data_x.val.label) {
                      let cell_val_x = cell_data_x.val.label;
                      if (cell_value_list_2[cell_val_x] === undefined) {
                        cell_value_list_2[cell_val_x] = {};
                      }
                      if (cell_data_y.val && cell_data_y.val.label) {
                        all_total += 1;
                        let cell_val_y = cell_data_y.val.label;
                        if (cell_value_list_2[cell_val_x][cell_val_y] === undefined) {
                          combination_count += 1;
                          cell_value_list_2[cell_val_x][cell_val_y] = { 'count': 0 };
                        }
                        cell_value_list_2[cell_val_x][cell_val_y]['count'] += 1;
                        cell_value_list_2[cell_val_x][cell_val_y]['result'] = Math.round((cell_value_list_2[cell_val_x][cell_val_y]['count'] / all_total) * 100);
                      }
                    }
                    for (let ccc_1 in cell_value_list_2) {
                      for (let ccc_2 in cell_value_list_2[ccc_1]) {
                        cell_value_list_2[ccc_1][ccc_2]['result'] = Math.round((cell_value_list_2[ccc_1][ccc_2]['count'] / all_total) * 100);
                      }
                    }
                  } else if (y_param_format === 'sum') {
                    if (cell_data_x.val && cell_data_x.val.label) {
                      let cell_val_x = cell_data_x.val.label;
                      all_total += 1;
                      if (cell_data_y.val && cell_data_y.val.label) {
                        let cell_val_y = '';
                        try {
                          cell_val_y = parseFloat(cell_data_y.val.label);
                        } catch (err) {}
                        if (cell_val_y !== '') {
                          if (cell_value_list_2[cell_val_x] === undefined) {
                            combination_count += 1;
                            cell_value_list_2[cell_val_x] = {
                              'count': 0,
                              'sum': 0
                            };
                          }
                          cell_value_list_2[cell_val_x]['count'] += 1;
                          cell_value_list_2[cell_val_x]['sum'] += cell_val_y;
                          cell_value_list_2[cell_val_x]['result'] = Math.round(cell_value_list_2[cell_val_x]['sum']);
                          // cell_value_list_2[cell_val_x]['result'] = Math.round(cell_value_list_2[cell_val_x]['result']);
                        }
                      }
                    }
                  }
                } else if (y_columns.length > 1) {
                  let x_column_index = parseInt(x_columns[0]['value']);
                  let cell_data_x = table_data.table[row_index][x_column_index];
                  // console.log('cell_data_x  :', cell_data_x);
                  if (y_param_format === 'count') {
                    if (cell_data_x.val && cell_data_x.val.label) {
                      let cell_val_x = cell_data_x.val.label;
                      let y_string_list = [];
                      for (let y_index in y_columns) {
                        let y_column_index = parseInt(y_columns[y_index]['value']);
                        let cell_data_y = table_data.table[row_index][y_column_index];
                        if (cell_data_y.val && cell_data_y.val.label) {
                          let cell_val_y = cell_data_y.val.label;
                          y_string_list.push(cell_val_y);
                        }
                      }
                      if (y_string_list.length > 0) {
                        if (cell_value_list_2[cell_val_x] === undefined) {
                          cell_value_list_2[cell_val_x] = {};
                        }
                        let y_merged = y_string_list.join('-');
                        if (cell_value_list_2[cell_val_x][y_merged] === undefined) {
                          cell_value_list_2[cell_val_x][y_merged] = { 'count': 0 };
                          combination_count += 1;
                        }
                        cell_value_list_2[cell_val_x][y_merged]['count'] += 1;
                        cell_value_list_2[cell_val_x][y_merged]['result'] = cell_value_list_2[cell_val_x][y_merged]['count'];
                      }
                    }
                  } else if (y_param_format === 'count_percent') {
                    if (cell_data_x.val && cell_data_x.val.label) {
                      let cell_val_x = cell_data_x.val.label;
                      let y_string_list = [];
                      for (let y_index in y_columns) {
                        let y_column_index = parseInt(y_columns[y_index]['value']);
                        let cell_data_y = table_data.table[row_index][y_column_index];
                        if (cell_data_y.val && cell_data_y.val.label) {
                          let cell_val_y = cell_data_y.val.label;
                          y_string_list.push(cell_val_y);
                        }
                      }
                      if (y_string_list.length > 0) {
                        if (cell_value_list_2[cell_val_x] === undefined) {
                          cell_value_list_2[cell_val_x] = {};
                        }
                        all_total += 1;
                        let y_merged = y_string_list.join('-');
                        if (cell_value_list_2[cell_val_x][y_merged] === undefined) {
                          combination_count += 1;
                          cell_value_list_2[cell_val_x][y_merged] = { 'count': 0 };
                        }
                        cell_value_list_2[cell_val_x][y_merged]['count'] += 1;
                        cell_value_list_2[cell_val_x][y_merged]['result'] = cell_value_list_2[cell_val_x][y_merged]['count'];
                      }
                    }
                    for (let ccc_1 in cell_value_list_2) {
                      for (let ccc_2 in cell_value_list_2[ccc_1]) {
                        cell_value_list_2[ccc_1][ccc_2]['result'] = Math.round((cell_value_list_2[ccc_1][ccc_2]['count'] / all_total) * 100);
                      }
                    }
                  } else if (y_param_format === 'sum') {
                    if (cell_data_x.val && cell_data_x.val.label) {
                      let cell_val_x = cell_data_x.val.label;
                      for (let y_index in y_columns) {
                        let y_column_index = parseInt(y_columns[y_index]['value']);
                        let y_column_label = y_columns[y_index]['label'];
                        let cell_data_y = table_data.table[row_index][y_column_index];
                        if (cell_data_y.val && cell_data_y.val.label) {
                          let cell_val_y = '';
                          try {
                            cell_val_y = parseFloat(cell_data_y.val.label);
                          } catch (err) {}
                          if (cell_val_y !== '') {
                            if (cell_value_list_2[cell_val_x] === undefined) {
                              cell_value_list_2[cell_val_x] = {};
                            }
                            if (cell_value_list_2[cell_val_x][y_column_label] === undefined) {
                              combination_count += 1;
                              cell_value_list_2[cell_val_x][y_column_label] = {
                                'count': 0,
                                'sum': 0
                              }
                            }
                            all_total += 1;
                            cell_value_list_2[cell_val_x][y_column_label]['count'] += 1;
                            cell_value_list_2[cell_val_x][y_column_label]['sum'] += cell_val_y;
                            cell_value_list_2[cell_val_x][y_column_label]['result'] = Math.round(cell_value_list_2[cell_val_x][y_column_label]['sum']);
                          }
                        }
                      }
                    }
                  }
                }
              } else if (x_columns.length === 2) {
                if (y_columns.length === 0) {
                  if (x_param_format === 'count') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    if (x_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      if (cell_value_list_2[x_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged] = { 'count': 0 };
                      }
                      cell_value_list_2[x_merged]['count'] += 1;
                      cell_value_list_2[x_merged]['result'] = cell_value_list_2[x_merged]['count'];
                    }
                  } else if (x_param_format === 'count_percent') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    if (x_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      all_total += 1;
                      if (cell_value_list_2[x_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged] = { 'count': 0 };
                      }
                      cell_value_list_2[x_merged]['count'] += 1;
                      cell_value_list_2[x_merged]['result'] = cell_value_list_2[x_merged]['count'];
                    }
                    for (let ccc in cell_value_list_2) {
                      cell_value_list_2[ccc]['result'] = Math.round((cell_value_list_2[ccc]['count'] / all_total) * 100);
                    }
                  }
                } else if (y_columns.length === 1) {
                  if (y_param_format === 'count') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    let y_string_list = [];
                    for (let y_index in y_columns) {
                      let y_column_index = parseInt(y_columns[y_index]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      if (cell_data_y.val && cell_data_y.val.label) {
                        let cell_val_y = cell_data_y.val.label;
                        y_string_list.push(cell_val_y);
                      }
                    }
                    if (x_string_list.length > 0 && y_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_merged = y_string_list.join('-');
                      if (cell_value_list_2[x_merged] === undefined) {
                        cell_value_list_2[x_merged] = {};
                      }
                      if (cell_value_list_2[x_merged][y_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged][y_merged] = { 'count': 0, 'result': '' };
                      }
                      cell_value_list_2[x_merged][y_merged]['count'] += 1;
                      cell_value_list_2[x_merged][y_merged]['result'] = cell_value_list_2[x_merged][y_merged]['count'];
                    }
                  } else if (y_param_format === 'count_percent') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    let y_string_list = [];
                    for (let y_index in y_columns) {
                      let y_column_index = parseInt(y_columns[y_index]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      if (cell_data_y.val && cell_data_y.val.label) {
                        let cell_val_y = cell_data_y.val.label;
                        y_string_list.push(cell_val_y);
                      }
                    }
                    if (x_string_list.length > 0 && y_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_merged = y_string_list.join('-');
                      all_total += 1;
                      if (cell_value_list_2[x_merged] === undefined) {
                        cell_value_list_2[x_merged] = {};
                      }
                      if (cell_value_list_2[x_merged][y_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged][y_merged] = { 'count': 0, 'result': '' };
                      }
                      cell_value_list_2[x_merged][y_merged]['count'] += 1;
                    }
                    for (let ccc_1 in cell_value_list_2) {
                      for (let ccc_2 in cell_value_list_2[ccc_1]) {
                        cell_value_list_2[ccc_1][ccc_2]['result'] = Math.round((cell_value_list_2[ccc_1][ccc_2]['count'] / all_total) * 100);
                      }
                    }
                  } else if (y_param_format === 'sum') {
                    let y_column_index_1 = parseInt(y_columns[0]['value']);
                    let x_column_index_1 = parseInt(x_columns[0]['value']);
                    let x_column_index_2 = parseInt(x_columns[1]['value']);
                    let cell_data_y = table_data.table[row_index][y_column_index_1];
                    let is_y_eligible = false;
                    let cell_val_y = '';
                    if (cell_data_y.val && cell_data_y.val.label) {
                      try {
                        cell_val_y = parseFloat(cell_data_y.val.label);
                      } catch (err) {}
                      if (cell_val_y !== '') {
                        is_y_eligible = true;
                      }
                    }
                    if (is_y_eligible) {
                      let cell_data_x_1 = table_data.table[row_index][x_column_index_1];
                      let cell_data_x_2 = table_data.table[row_index][x_column_index_2];
                      if (cell_data_x_1.val && cell_data_x_1.val.label && cell_data_x_2.val && cell_data_x_2.val.label) {
                        if (cell_value_list_2[cell_data_x_1.val.label] === undefined) {
                          cell_value_list_2[cell_data_x_1.val.label] = {};
                        }
                        if (cell_value_list_2[cell_data_x_1.val.label][cell_data_x_2.val.label] === undefined) {
                          combination_count += 1;
                          cell_value_list_2[cell_data_x_1.val.label][cell_data_x_2.val.label] = { 'sum': 0, 'result': '' };
                        }
                        cell_value_list_2[cell_data_x_1.val.label][cell_data_x_2.val.label]['sum'] += cell_val_y;
                        cell_value_list_2[cell_data_x_1.val.label][cell_data_x_2.val.label]['result'] = Math.round(cell_value_list_2[cell_data_x_1.val.label][cell_data_x_2.val.label]['sum']);
                      }
                    }
                  }
                } else if (y_columns.length > 1) {
                  if (y_param_format === 'count') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    let y_string_list = [];
                    for (let y_index in y_columns) {
                      let y_column_index = parseInt(y_columns[y_index]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      if (cell_data_y.val && cell_data_y.val.label) {
                        let cell_val_y = cell_data_y.val.label;
                        y_string_list.push(cell_val_y);
                      }
                    }
                    if (x_string_list.length > 0 && y_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_merged = y_string_list.join('-');
                      if (cell_value_list_2[x_merged] === undefined) {
                        cell_value_list_2[x_merged] = {};
                      }
                      if (cell_value_list_2[x_merged][y_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged][y_merged] = { 'count': 0, 'result': '' };
                      }
                      cell_value_list_2[x_merged][y_merged]['count'] += 1;
                      cell_value_list_2[x_merged][y_merged]['result'] = cell_value_list_2[x_merged][y_merged]['count'];
                    }
                  } else if (y_param_format === 'count_percent') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    let y_string_list = [];
                    for (let y_index in y_columns) {
                      let y_column_index = parseInt(y_columns[y_index]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      if (cell_data_y.val && cell_data_y.val.label) {
                        let cell_val_y = cell_data_y.val.label;
                        y_string_list.push(cell_val_y);
                      }
                    }
                    if (x_string_list.length > 0 && y_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_merged = y_string_list.join('-');
                      all_total += 1;
                      if (cell_value_list_2[x_merged] === undefined) {
                        cell_value_list_2[x_merged] = {};
                      }
                      if (cell_value_list_2[x_merged][y_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged][y_merged] = { 'count': 0, 'result': '' };
                      }
                      cell_value_list_2[x_merged][y_merged]['count'] += 1;
                    }
                    for (let ccc_1 in cell_value_list_2) {
                      for (let ccc_2 in cell_value_list_2[ccc_1]) {
                        cell_value_list_2[ccc_1][ccc_2]['result'] = Math.round((cell_value_list_2[ccc_1][ccc_2]['count'] / all_total) * 100);
                      }
                    }
                  }
                }
              } else if (x_columns.length > 2) {
                // Y control......
                if (y_columns.length === 0) {
                  if (x_param_format === 'count') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    if (x_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      if (cell_value_list_2[x_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged] = { 'count': 0 };
                      }
                      cell_value_list_2[x_merged]['count'] += 1;
                      cell_value_list_2[x_merged]['result'] = cell_value_list_2[x_merged]['count'];
                    }
                  } else if (x_param_format === 'count_percent') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    if (x_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      all_total += 1;
                      if (cell_value_list_2[x_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged] = { 'count': 0 };
                      }
                      cell_value_list_2[x_merged]['count'] += 1;
                      cell_value_list_2[x_merged]['result'] = cell_value_list_2[x_merged]['count'];
                    }
                    for (let ccc in cell_value_list_2) {
                      cell_value_list_2[ccc]['result'] = Math.round((cell_value_list_2[ccc]['count'] / all_total) * 100);
                    }
                  }
                } else if (y_columns.length === 1) {
                  if (y_param_format === 'count') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    if (x_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_column_index = parseInt(y_columns[0]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      if (cell_data_y.val && cell_data_y.val.label) {
                        if (cell_value_list_2[x_merged][cell_data_y.val.label] === undefined) {
                          combination_count += 1;
                          cell_value_list_2[x_merged][cell_data_y.val.label] = { 'count': 0, 'result': '' };
                        }
                        cell_value_list_2[x_merged][cell_data_y.val.label] += 1;
                        cell_value_list_2[x_merged][cell_data_y.val.label]['result'] = cell_value_list_2[x_merged][cell_data_y.val.label];
                      }
                    }
                  } else if (y_param_format === 'count_percent') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    if (x_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_column_index = parseInt(y_columns[0]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      if (cell_data_y.val && cell_data_y.val.label) {
                        all_total += 1;
                        if (cell_value_list_2[x_merged][cell_data_y.val.label] === undefined) {
                          combination_count += 1;
                          cell_value_list_2[x_merged][cell_data_y.val.label] = { 'count': 0, 'result': '' };
                        }
                        cell_value_list_2[x_merged][cell_data_y.val.label] += 1;
                        cell_value_list_2[x_merged][cell_data_y.val.label]['result'] = cell_value_list_2[x_merged][cell_data_y.val.label];
                      }
                    }
                    for (let ccc_1 in cell_value_list_2) {
                      for (let ccc_2 in cell_value_list_2[ccc_1]) {
                        cell_value_list_2[ccc_1][ccc_2]['result'] = Math.round((cell_value_list_2[ccc_1][ccc_2]['count'] / all_total) * 100);
                      }
                    }
                  } else if (y_param_format === 'sum') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    if (x_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_column_index = parseInt(y_columns[0]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      let is_y_eligible = false;
                      let cell_val_y = '';
                      if (cell_data_y.val && cell_data_y.val.label) {
                        try {
                          cell_val_y = parseFloat(cell_data_y.val.label);
                        } catch (err) {}
                        if (cell_val_y !== '') {
                          is_y_eligible = true;
                        }
                      }
                      if (is_y_eligible) {
                        if (cell_value_list_2[x_merged] === undefined) {
                          combination_count += 1;
                          cell_value_list_2[x_merged] = { 'sum': 0, 'result': '' };
                        }
                        cell_value_list_2[x_merged]['sum'] += cell_val_y;
                        cell_value_list_2[x_merged]['result'] = Math.round(cell_value_list_2[x_merged]['sum']);
                      }
                    }
                  }
                } else if (y_columns.length > 1) {
                  if (y_param_format === 'count') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    let y_string_list = [];
                    for (let y_index in y_columns) {
                      let y_column_index = parseInt(y_columns[y_index]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      if (cell_data_y.val && cell_data_y.val.label) {
                        let cell_val_y = cell_data_y.val.label;
                        y_string_list.push(cell_val_y);
                      }
                    }
                    if (x_string_list.length > 0 && y_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_merged = y_string_list.join('-');
                      if (cell_value_list_2[x_merged] === undefined) {
                        cell_value_list_2[x_merged] = {};
                      }
                      if (cell_value_list_2[x_merged][y_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged][y_merged] = { 'count': 0, 'result': '' };
                      }
                      cell_value_list_2[x_merged][y_merged]['count'] += 1;
                      cell_value_list_2[x_merged][y_merged]['result'] = cell_value_list_2[x_merged][y_merged]['count'];
                    }
                  } else if (y_param_format === 'count_percent') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    let y_string_list = [];
                    for (let y_index in y_columns) {
                      let y_column_index = parseInt(y_columns[y_index]['value']);
                      let cell_data_y = table_data.table[row_index][y_column_index];
                      if (cell_data_y.val && cell_data_y.val.label) {
                        let cell_val_y = cell_data_y.val.label;
                        y_string_list.push(cell_val_y);
                      }
                    }
                    if (x_string_list.length > 0 && y_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      let y_merged = y_string_list.join('-');
                      all_total += 1;
                      if (cell_value_list_2[x_merged] === undefined) {
                        cell_value_list_2[x_merged] = {};
                      }
                      if (cell_value_list_2[x_merged][y_merged] === undefined) {
                        combination_count += 1;
                        cell_value_list_2[x_merged][y_merged] = { 'count': 0, 'result': '' };
                      }
                      cell_value_list_2[x_merged][y_merged]['count'] += 1;
                    }
                    for (let ccc_1 in cell_value_list_2) {
                      for (let ccc_2 in cell_value_list_2[ccc_1]) {
                        cell_value_list_2[ccc_1][ccc_2]['result'] = Math.round((cell_value_list_2[ccc_1][ccc_2]['count'] / all_total) * 100);
                      }
                    }
                  } else if (y_param_format === 'sum') {
                    let x_string_list = [];
                    for (let x_index in x_columns) {
                      let x_column_index = parseInt(x_columns[x_index]['value']);
                      let cell_data_x = table_data.table[row_index][x_column_index];
                      if (cell_data_x.val && cell_data_x.val.label) {
                        let cell_val_x = cell_data_x.val.label;
                        x_string_list.push(cell_val_x);
                      }
                    }
                    if (x_string_list.length > 0) {
                      let x_merged = x_string_list.join('-');
                      for (let y_index in y_columns) {
                        let y_column_index = parseInt(y_columns[y_index]['value']);
                        let y_column_label = parseInt(y_columns[y_index]['label']);
                        let cell_data_y = table_data.table[row_index][y_column_index];
                        let is_y_eligible = false;
                        let cell_val_y = '';
                        if (cell_data_y.val && cell_data_y.val.label) {
                          try {
                            cell_val_y = parseFloat(cell_data_y.val.label);
                          } catch (err) {}
                          if (cell_val_y !== '') {
                            is_y_eligible = true;
                          }
                        }
                        if (is_y_eligible) {
                          if (cell_value_list_2[x_merged] === undefined) {
                            cell_value_list_2[x_merged] = {};
                          }
                          if (cell_value_list_2[x_merged][y_column_label] === undefined) {
                            combination_count += 1;
                            cell_value_list_2[x_merged][y_column_label] = { 'sum': 0, 'result': '' };
                          }
                          cell_value_list_2[x_merged][y_column_label]['sum'] += cell_val_y;
                          cell_value_list_2[x_merged][y_column_label]['result'] = Math.round(cell_value_list_2[x_merged][y_column_label]['sum']);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // console.log('combination_count : ', combination_count);
        if (combination_count > 1000) {
          is_eligible = false;
          alert('Kombinasyon adedi ' + combination_count + ' den fazla. Lütfen kontrol ediniz.');
          break;
        }
      }
      // console.log('combination_count : ', combination_count);
      if (is_eligible) {
        if (prepare_type === '1') {
          return cell_value_list_1;
        } else if (prepare_type === '2') {
          return cell_value_list_2;
        }
      } else {
        return {};
      }
    },
    f_resetCharts: function () {
      this.d_chartCreate.charts = [];
      this.d_chartCreate.x1_style = {};
      this.d_chartCreate.x2_style = {};
      this.$forceUpdate();
    },
    f_newColumn: function (column_type) {
      this.f_resetCharts();
      if (column_type === 'x') {
        this.d_chartCreate.x_columns.push({ 'value': '', 'label': '' });
      } else if (column_type === 'y') {
        this.d_chartCreate.y_columns.push({ 'value': '', 'label': '' });
      }
    },
    f_deleteColumn: function (column_type, the_index) {
      this.f_resetCharts();
      if (column_type === 'x') {
        this.d_chartCreate.x_columns.splice(the_index, 1);
      } else if (column_type === 'y') {
        this.d_chartCreate.y_columns.splice(the_index, 1);
      }
    },
    f_deleteView: function (report_index, table_index, view_index) {
      this.p_reportData.list[report_index].table_list[table_index].view.splice(view_index, 1);
      this.d_showReportModule = false;
      setTimeout(function () {
        this.d_showReportModule = true;
      }.bind(this), 50);
    },
    f_editView: function (report_index, table_index, view_index) {
      let table_data = JSON.parse(JSON.stringify(this.p_reportData.list[report_index].table_list[table_index]));
      this.d_chartCreate.chart_show = true;
      this.d_chartCreate.table_header = table_data.view[view_index].chart_create.table_header;
      this.d_chartCreate.chart_type = table_data.view[view_index].chart_create.chart_type;
      this.d_chartCreate.chart_width = table_data.view[view_index].chart_create.chart_width;
      this.d_chartCreate.charts = table_data.view[view_index].charts;
      this.d_chartCreate.dashboard_groupname = table_data.view[view_index].chart_create.dashboard_groupname;
      this.d_chartCreate.dimension_x1 = table_data.view[view_index].chart_create.dimension_x1;
      this.d_chartCreate.dimension_x2 = table_data.view[view_index].chart_create.dimension_x2;
      this.d_chartCreate.dimension_y = table_data.view[view_index].chart_create.dimension_y;
      this.d_chartCreate.info = table_data.view[view_index].chart_create.info;
      this.d_chartCreate.label = table_data.view[view_index].chart_create.label;
      this.d_chartCreate.order_x = table_data.view[view_index].chart_create.order_x;
      this.d_chartCreate.show_in_dashboard = table_data.view[view_index].chart_create.show_in_dashboard;
      this.d_chartCreate.widht_for_every_bar = table_data.view[view_index].chart_create.widht_for_every_bar;
      this.d_chartCreate.x1_style = table_data.view[view_index].chart_create.x1_style;
      this.d_chartCreate.x2_style = table_data.view[view_index].chart_create.x2_style;
      this.d_chartCreate.x_columns = table_data.view[view_index].chart_create.x_columns;
      this.d_chartCreate.x_param_format = table_data.view[view_index].chart_create.x_param_format;
      this.d_chartCreate.y_columns = table_data.view[view_index].chart_create.y_columns;
      this.d_chartCreate.y_param_format = table_data.view[view_index].chart_create.y_param_format;
      // this.d_chartCreate = JSON.parse(JSON.stringify(table_data.view[view_index].chart_create));
      if (this.p_reportData.list[report_index].table_list[table_index].table_header.length === this.d_chartCreate.table_header.length) {
        for (let i in this.d_chartCreate.table_header) {
          // console.log('this.d_chartCreate.table_header[i].selected_options ', this.d_chartCreate.table_header[i].selected_options);
          if (this.d_chartCreate.table_header[i].selected_options.length > 0) {
            this.p_reportData.list[report_index].table_list[table_index].table_header[i].selected_options = JSON.parse(JSON.stringify(this.d_chartCreate.table_header[i].selected_options));
          } else {
            this.p_reportData.list[report_index].table_list[table_index].table_header[i].selected_options = [];
          }
        }
      }
      let table_ref = 'ref_' + this.p_reportData.index + '_' + this.p_reportData.table_index;
      this.$refs[table_ref].f_filterTable();
      this.d_chartCreate.edit_report_index = table_index;
      this.d_chartCreate.edit_table_index = table_index;
      this.d_chartCreate.edit_view_index = view_index;
      this.d_chartCreate.process = 'edit';
      this.d_chartCreate.show = true;
      // this.$forceUpdate();
      // setTimeout(function () {}.bind(this), 50);
    },
    f_selectViewMode: function (view_mode) {
      this.d_selectedTab = view_mode;
      this.$forceUpdate();
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
  },
  watch: {
    'p_reportDashboard.change': function () {
      // console.log(this.p_reportDashboard.change);
      // this.f_createDashboardRows();
      // this.$forceUpdate();
    },
    'p_reportData.status': function () {
      if (this.p_reportData.status === 0) {
        this.d_showReportModule = false;
      } else {
        this.d_showReportModule = true;
      }
      // console.log('this.d_showReportModule : ', this.d_showReportModule);
    },
    'p_reportData.index': function () {
      // this.$forceUpdate();
      // console.log('this.p_reportData.index : ', this.p_reportData.index);
    },
    'p_reportData.table_index': function () {
      this.d_showReportModule = false;
      setTimeout(function () {
        this.d_showReportModule = true;
      }.bind(this), 50);
    },
    'd_searchDashboardText': function () {
      this.d_searchViewList = [];
      setTimeout(function () {
        this.f_createDashboardRows();
      }.bind(this), 500);
    }
  }
};

</script>

<style type="text/css">
canvas {
  background-color: white;
}

</style>

