<template>
  <div>
    <report-module v-if="d_reportList.list.length > 0" ref="ref_report_module" :p_reportData="d_reportData" :p_reportList="d_reportList" :f_selectThisreport="f_selectThisreport" :f_reportViewRecord="f_reportViewRecord" :p_reportDashboard="d_reportDashboard"></report-module>
  </div>
</template>

<script>
import ReportService from '@/services/report';
import store from '@/store';
import ReportModule from '@/components/widgets/ReportModule';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'report_list',
  data () {
    return {
      d_reportList: {
        'list': [],
        'show': false,
        'index': ''
      },
      d_reportDashboard: {
        'change': 0,
        'other': { 'order': [], 'list': {} },
        'dashboard_group': { 'order': [], 'list': {} }
      },
      d_reportData: { 'list': [], 'index': '', 'status': 0, 'table_index': '' },
      d_timerControlUpdate: { 'timer': 0, 'status': 'passive' },
    };
  },
  components: {
    ReportModule,
  },
  computed: mapGetters({
    help: 'help'
  }),
  created: function () {
    /*
      {
          "project_id": "545690ab-fcc4-44dd-b54c-54c036dba13e",
          "id": "872439bf-b86b-449c-b22d-8b1841c4c6c2",
          "name": "Makale Ba\u015fl\u0131\u011f\u0131: Test, "
        }, {
          "project_id": "6f1ea1e5-31f7-4a19-8f2c-89294ba15e06",
          "id": "edc0260a-a3ed-452d-bb4d-92eb8afdf93b",
          "name": "Makale Ba\u015fl\u0131\u011f\u0131: T\u00fcp mide ameliyat\u0131 sonras\u0131nda hastalar\u0131n birinci y\u0131l kontrol\u00fcnde karaci\u011fer ya\u011flanmas\u0131n\u0131n biyokimyasal ve inflamatuar parametrelerle korelasyonun de\u011ferlendirilmesi, "
        }, {
          "project_id": "4faa8094-7833-47af-9b16-cfaeb5bf1e62",
          "id": "845ec201-3df8-4426-bcb5-65b9983e4a47",
          "name": "Makale Ba\u015fl\u0131\u011f\u0131: Kolon ca vakalar\u0131nda patolojik T evresininin hastan\u0131n klinik ve laboratuar parametreleriyle tahmin edilmesi, "
        }, {
          "project_id": "840bc2ce-262e-4f02-b416-8421098e9504",
          "id": "2298536f-ba1c-42aa-9936-9a5f88598fc6",
          "name": "Makale Ba\u015fl\u0131\u011f\u0131: test, "
        }, {
          "project_id": "dbabe44b-1ca7-4a48-892f-b5e0a4270fbe",
          "id": "cb2a5ba8-eea1-4cd0-83ac-4da0c0981ab9",
          "name": "Makale Ba\u015fl\u0131\u011f\u0131: Nash hastalar\u0131nda prediktif fakt\u00f6rlerin analizi, "
        }, {
          "project_id": "72056de9-e413-454a-b14f-7b6f822fe7ac",
          "id": "c6895765-473e-49c2-a28c-b2f7c631a0f7",
          "name": "Makale Ba\u015fl\u0131\u011f\u0131: \u015eube B\u00f6l\u00fcm ve Cinsiyet \u0130li\u015fkileri, "
        }, {
          "project_id": "33d89a0c-16f6-4344-9856-a488b1b08543",
          "id": "03b3ecd7-2403-485a-8ec9-d6dd0c8c12c5",
          "name": "Makale Başlığı: Protokol Gelir Bilgileri"
        }
    */
    this.f_reportList();
    /*
        if (this.d_timerControlProjectUpdate.status !== 'active') {
          this.d_timerControlProjectUpdate.status = 'active';
          this.d_timerControlProjectUpdate.timer = setInterval(function () {
            // console.log("this.d_project_id ", this.d_project_id);
            this.f_goToThisProject(this.d_project_id);
          }.bind(this), 30000);
        }

    */
  },
  mounted () {},
  destroyed () {
    window.clearInterval(this.d_timerControlUpdate.timer);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_reportDashboardList: function () {
      this.d_reportDashboard.change = 0;
      this.d_reportDashboard.other = { 'order': [], 'list': {} };
      this.d_reportDashboard.dashboard_group = { 'order': [], 'list': {} };
      ReportService.report_dashboard_list()
        .then(resp => {
          if (resp.data.status_code === '2000') {
            this.d_reportDashboard.change = 1;
            this.d_reportDashboard.other = resp.data.other;
            this.d_reportDashboard.dashboard_group = resp.data.dashboard_group;
            this.$refs.ref_report_module.f_createDashboardRows();
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_reportList: function () {
      ReportService.report_list()
        .then(resp => {
          if (resp.data.status_code === '2000') {
            this.d_reportList.list = resp.data.list;
            this.f_reportDashboardList();
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_selectThisreport: function (report_index) {
      this.d_reportData.index = '';
      this.d_reportData.status = 0;
      this.d_reportList.index = report_index;
      this.d_reportList.show = false;
      let query = 'report_id_list=' + this.d_reportList.list[report_index].id;
      ReportService.report_details(query)
        .then(resp => {
          this.d_reportData.status = 1;
          if (resp.data.status_code === '2000') {
            this.d_reportData.list = resp.data.list;
            if (this.d_reportData.list.length === 1) {
              this.d_reportData.index = 0;
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_reportViewRecord: function (report_index, record_type, view_list = [], table_index = '') {
      let query = 'project_id=' + this.d_reportData.list[report_index].project_id;
      query += '&report_id=' + this.d_reportData.list[report_index].id;
      query += '&record_type=' + record_type;
      let data = { 'view_list': view_list, 'table_index': table_index };
      ReportService.report_view_record(query, data)
        .then(resp => {
          if (resp.data.status_code === '2000') {
            alert('successfully recorded');
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    }
  },
  watch: {}
};

</script>

<style type="text/css"></style>

